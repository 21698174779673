import { Row } from "antd";
import Router from "next/router";
import React from "react";

class UnAuthenticatedHeader extends React.Component {
  static handleLogoClick(ev) {
    ev.preventDefault();
    Router.push("https://dexecure.com");
  }

  render() {
    return (
      <header className="dashboard--header">
        <Row type="flex" justify="center" align="middle">
          <div className="header--left">
            <a href="#section" onClick={UnAuthenticatedHeader.handleLogoClick}>
              <img src="static/full-logo-white.png" alt="Dexecure Dashboard" />
            </a>
          </div>
        </Row>
        <style jsx>
          {`
            .dashboard--header {
              background-color: #153b58;
              padding: 1em;
            }
            .dashboard--header .l {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          `}
        </style>
      </header>
    );
  }
}

export default UnAuthenticatedHeader;
