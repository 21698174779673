import TagManager from "react-gtm-module";

export const initGTM = () => {
  const tagManagerArgs = {
    gtmId: "GTM-PBDLRND",
  };

  TagManager.initialize(tagManagerArgs);
};

export default undefined;
