import Router from "next/router";
import React from "react";
import withoutAuthentication from "components/Shared//withoutAuthentication";

class HomePage extends React.Component {
  constructor() {
    super();
    const isClient = typeof localStorage !== "undefined";
    if (isClient) {
      Router.push("/signin");
    }
  }

  render() {
    return <div />;
  }
}

export default withoutAuthentication(HomePage);
