import React from "react";

const Loading = () => (
  <div>
    <img
      src="static/loading.svg"
      style={{ position: "fixed", top: "50%", left: "50%" }}
      alt="Loading..."
    />
  </div>
);
export default Loading;
