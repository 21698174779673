import { Layout } from "antd";
import Router from "next/router";
import React, { Component } from "react";
import Script from "react-load-script";
import { initGA, logPageView } from "utils/analytics";
import { initGTM } from "utils/gtm";
import Loading from "./Loading";
import Meta from "./meta";
import UnAuthenticatedHeader from "./UnAuthenticatedHeader";

export default function WithoutAuthentication(SubComponent) {
  const isServer = typeof localStorage === "undefined";
  const { Header, Content } = Layout;
  return class UnAuthenticatedComponent extends Component {
    constructor(props) {
      super(props);
      let token;
      if (!isServer) {
        token = localStorage.getItem("token");
      }
      if (token) {
        Router.push("/dashboard");
      }
      const loading = Boolean(token);
      this.state = {
        loading,
        token,
      };
    }

    static async getInitialProps(ctx) {
      const componentProps =
        SubComponent.getInitialProps &&
        (await SubComponent.getInitialProps(ctx));
      return { ...componentProps };
    }

    componentDidMount() {
      if (!window.GA_INITIALIZED) {
        initGA();
        window.GA_INITIALIZED = true;
      }
      logPageView();
      if (!window.GTM_INITIALIZED) {
        initGTM();
        window.GTM_INITIALIZED = true;
      }
    }

    render() {
      if (this.state.loading) {
        return (
          <div>
            {/* <React.StrictMode> */}
            <Meta />
            <Loading />
            {/* </React.StrictMode> */}
          </div>
        );
      }
      return (
        // <React.StrictMode>
        <Layout>
          <Header>
            <UnAuthenticatedHeader handleLogout={this.handleLogout} />
            <Meta />
          </Header>
          <Content>
            <div className="svg-background">
              <svg viewBox="0 0 2964.84 2267.51">
                <g id="Layer_2">
                  <g id="Layer_1-2">
                    <g opacity="0.13">
                      <ellipse
                        cx="2264.86"
                        cy="1605.13"
                        fill="none"
                        rx="696.95"
                        ry="659.36"
                        stroke="#468ecc"
                        strokeDasharray="18.21 18.21"
                        strokeWidth="6.06"
                      />
                    </g>
                    <g opacity="0.13">
                      <ellipse
                        cx="699.98"
                        cy="662.38"
                        fill="none"
                        rx="696.95"
                        ry="659.36"
                        stroke="#468ecc"
                        strokeDasharray="18.21 18.21"
                        strokeWidth="6.06"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <SubComponent {...this.props} token={this.state.token} />
            <link rel="stylesheet" href="https://use.typekit.net/rgu6gkq.css" />
          </Content>
          <Script
            url="https://cdn.slaask.com/chat.js"
            onError={() => {
              console.log("slaask failed to load");
            }}
            onLoad={() => window._slaask.init(process.env.slack_id)}
          />
          <style>
            {`
            .ant-layout-header {
              padding: 0;
              height:auto;
              background-color: #153b58;

            }
            @media screen and (min-width: 768px) and (max-width: 6000px) { 
            .ant-layout{                                                 
              height: 100vh;
              }
              
            }`}
          </style>
        </Layout>
        // </React.StrictMode>
      );
    }
  };
}
